import React, {useEffect, useState} from 'react';
import Modal from "../Modal";
import {Swiper, SwiperSlide} from "swiper/react";
import PrevButton from "../PrevButton/PrevButton";
import NextButton from "../NextButton/NextButton";
import styles from './GallerySlider.module.scss'
import SwiperClass from "swiper/types/swiper-class";
import {useWindowWidth} from "../../hooks/useWindowWidth";


interface GallerySliderProps {
  images: (string | null)[]
  activeImage: number | string
  isOpen: boolean
  onClose: () => void
}
const GallerySlider = ({images, activeImage, onClose, isOpen}: GallerySliderProps) => {
  const [swiper, setSwiper] = useState<SwiperClass>()
  const width = useWindowWidth()

  return (
    width > 1200 ? <Modal className={styles.modal} isOpen={isOpen} onRequestClose={onClose}>
      <div className={styles.sliderRoot}>
        <Swiper
          loop={true}
          onSwiper={setSwiper}
          slidesPerView={1}
          onInit={(swiper) => swiper.slideTo(typeof activeImage == 'string' ? images.findIndex(e => e == activeImage) : activeImage)}
          className={styles.swiperModal}
        >
          {images.map((item, index) => (
            <SwiperSlide className={styles.swiperSlide} key={item}>
              <img src={item} className={styles.imgModal} alt=""/>
            </SwiperSlide>
          ))}
        </Swiper>
        {images.length > 1 && <>
          <PrevButton onClick={() => {
            swiper.slidePrev()
          }} className={styles.prev}/>
          <NextButton
            onClick={() => {
              swiper.slideNext()
            }}
            className={styles.next}
            allSlides={images.length - 1}
          />
        </>}
      </div>
    </Modal> : <></>
  );
};

export default GallerySlider;