import { Circle } from '../Circle';
import Popup from 'reactjs-popup';
import styles from './DistSvg.module.css';
import { useWindowWidth } from '../../../../hooks/useWindowWidth';
import { useRef, useState, useEffect } from 'react';
import CloseIcon from "../../../CloseIcon/CloseIcon";

const Modal = ({ trigger, title, tooltip, text, isOpen, onClose, onOpen }) => {
  const windowWidth = useWindowWidth();

  // Заменяем пробелы на "+"
  const formattedTooltip = tooltip.replace(/ /g, "+");

  return (
      <Popup
          trigger={trigger}
          open={isOpen}
          onOpen={onOpen}
          position="top center"
          onClose={onClose}
          on="click"
          closeOnDocumentClick
          className="dist-modal"
          modal={windowWidth && windowWidth <= 990}
      >
        {(close) => (
            <div className={styles.modal}>
              <button className={styles.button} onClick={close}>
                <CloseIcon />
              </button>
              <h2 className={styles.modal__title}>{title}</h2>
              <p className={styles.modal__text}>{text}</p>
              <a
                  href={`/distributor?region=${formattedTooltip}`}
                  onClick={close}
                  style={{ color: '#13AD37', display: 'flex', alignItems: 'center' }}
              >
                Перейти к дистрибьютору
                <svg
                    fill="#13AD37"
                    height="20"
                    width="20"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 330 330"
                    xmlSpace="preserve"
                    style={{ marginLeft: '5px' }}
                >
                  <path
                      id="XMLID_222_"
                      d="M250.606,154.389l-150-149.996c-5.857-5.858-15.355-5.858-21.213,0.001
                c-5.857,5.858-5.857,15.355,0.001,21.213l139.393,139.39L79.393,304.394c-5.857,5.858-5.857,15.355,0.001,21.213
                C82.322,328.536,86.161,330,90,330s7.678-1.464,10.607-4.394l149.999-150.004c2.814-2.813,4.394-6.628,4.394-10.606
                C255,161.018,253.42,157.202,250.606,154.389z"
                  />
                </svg>
              </a>
            </div>
        )}
      </Popup>
  );
};

const DistSvg = ({ cx, cy, d, title, text, tooltipRef, tooltip }) => {
  const [mouseX, setMouseX] = useState();
  const [mouseY, setMouseY] = useState();
  const [active, setActive] = useState(false);
  const ref = useRef();
  const windowWidth = useWindowWidth();
  const path = ref.current;
  const container = tooltipRef.current;

  useEffect(() => {
    const update = (e) => {
      setMouseX(e.x);
      setMouseY(e.y);
    };

    const showTooltip = () => {
      const width = container.offsetWidth;
      const height = container.offsetHeight;
      container.innerHTML = tooltip;
      container.style.left = mouseX - width / 2 + 'px';
      container.style.top = mouseY - height - 20 + 'px';
      container.style.visibility = 'visible';
    };

    windowWidth && windowWidth > 990 && showTooltip();

    if (path) path.addEventListener('mousemove', update);

    if (active) {
      container.style.visibility = 'hidden';
    }

    return () => {
      if (path) path.removeEventListener('mousemove', update);
    };
  }, [
    setMouseX,
    setMouseY,
    path,
    windowWidth,
    container,
    mouseX,
    mouseY,
    tooltip,
    active,
  ]);

  useEffect(() => {
    const hideTooltip = () => {
      container.style.visibility = 'hidden';
    };

    if (path) path.addEventListener('mouseout', hideTooltip);

    return () => {
      if (path) path.removeEventListener('mouseout', hideTooltip);
    };
  }, [container, path, active]);

  return (
      <g className="dist-group">
        <path
            ref={ref}
            onClick={() => setActive(true)}
            d={d}
            fill="#13AD37"
            className={`dist-region ${active ? 'active' : ''}`}
            stroke="#454545"
            strokeWidth="1.15734"
            strokeLinecap="round"
            strokeLinejoin="round"
        />

        <Modal
            isOpen={active}
            title={title}
            tooltip={tooltip} // Передаем tooltip в Modal
            onClose={() => setActive(false)}
            onOpen={() => setActive(true)}
            text={text}
            trigger={<Circle cx={cx} cy={cy} />}
        />
      </g>
  );
};

export { DistSvg };
