import { Title } from '../Title'
import { Wrapper } from '../Wrapper'
import styles from './Companies.module.css'
import { useState, useRef, useEffect } from 'react';
import Link from "next/link";

const COMPANIES = [
  {
    image: '/images/companies/card-1.png',
    link: 'https://www.cbr.ru/',
  },
  {
    image: '/images/companies/card-2.png',
    link: 'https://mvd.ru/',
  },
  {
    image: '/images/companies/card-3.png',
    link: 'https://pfr.gov.ru/',
  },
  {
    image: '/images/companies/card-4.png',
    link: 'https://www.sberbank.com/ru',
  },
  {
    image: '/images/companies/card-5.png',
    link: 'https://supcourt.ru/',
  },
  {
    image: '/images/companies/card-6.png',
    link: 'https://www.rzd.ru/',
  },
  {
    image: '/images/companies/card-7.png',
    link:
      'https://xn--b1aew.xn--p1ai/mvd/structure1/Glavnie_upravlenija/Glavnoe_upravlenie_jekonomicheskoj_bezop',
  },
  {
    image: '/images/companies/card-8.png',
    link: 'https://www.vtb.ru/',
  },
  {
    image: '/images/companies/card-9.png',
    link: 'https://www.royalpark.su/',
  },
  {
    image: '/images/companies/card-10.png',
    link: '',
  },
  {
    image: '/images/companies/card-11.png',
    link: 'https://hotelvidgof.ru/',
  },
  {
    image: '/images/companies/card-12.png',
    link: 'https://www.sibmoll.ru/',
  },
]

const Companies = ({...props}) => {
  const [isAnimated, setIsAnimated] = useState();
  const animRef = useRef();
  const blockRef = useRef()

  useEffect(() => {
    const options = {thresholds: [0.01]}
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      if (entry.isIntersecting) {
        setIsAnimated(true);
      }
    }, options);
    observer.observe(animRef.current);
  }, []);
  return (
    <section className={styles.root}>
      <Wrapper  className={styles.wrapper}>
        <Title title="Нашу продукцию используют" className={styles.title} />
        <div className={styles.items}>
          {COMPANIES.map(({ image, link }, index) => {
            return (
              <Link
                ref={index === 0 ? animRef : blockRef}
                key={index}
                href={link ? link : ''}
                onClick={(e) => {
                  if(!link) {
                   e.preventDefault()
                  }
                }}
                rel='noreferrer'
                target='_blank'
                className={`link ${styles.card} ${
                  isAnimated ? styles[`card_active_${index + 1}`] : ''
                } `}
              >
                <img className={styles.image} src={image} alt="Company logo" />
              </Link>
            )
          })}
        </div>
      </Wrapper>
    </section>
  )
}

export { Companies }
