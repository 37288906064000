import styles from './CatalogCard.module.css';
import { useState, useRef, useEffect } from 'react';
import Link from "next/link";

const CatalogCard = ({ title, image, number, className = '', link, index }) => {
  const [isAnimated, setIsAnimated] = useState();
  const [isAnimatedTwice, setIsAnimatedTwice] = useState();
  const animRef = useRef(null);
  const [backgroundPosition, setBackgroundPosition] = useState({
    top: 0,
    left: 0,
  });
  const [backgroundSize, setBackgroundSize] = useState(0);

  const onMouseEnterHandler = (event) => {
    const $target = event.currentTarget.getBoundingClientRect();

    setBackgroundPosition({
      top: event.clientY - $target.top,
      left: event.clientX - $target.left,
    });
    setBackgroundSize(event.currentTarget.offsetWidth * 3);
  };

  const onMouseLeaveHandler = (event) => {
    const $target = event.currentTarget.getBoundingClientRect();

    setBackgroundSize(0);
    setBackgroundPosition({
      top: event.clientY - $target.top,
      left: event.clientX - $target.left,
    });
  };


  useEffect(() => {
  const options = {thresholds: [0.1]}
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      if (entry.isIntersecting) {
        setIsAnimated(true);
      }
    }, options);
    observer.observe(animRef.current);
  }, []);

  useEffect(() => {
    if (isAnimated) setTimeout(() => setIsAnimatedTwice(true), 400);
  }, [isAnimated]);

  return (
    <Link
      href={link}
      style={{
        transitionDelay: (number-1)*0.05 + 's'
      }}
      className={`link ${styles.root} ${className} ${
        isAnimated ? styles[`root_active`] : ''
      }`}
      onMouseEnter={(event) => onMouseEnterHandler(event)}
      onMouseLeave={(event) => onMouseLeaveHandler(event)}
      ref={animRef}
    >
      <div
        className={styles.background}
        style={{
          width: `${backgroundSize}px`,
          height: `${backgroundSize}px`,
          top: `${backgroundPosition.top}px`,
          left: `${backgroundPosition.left}px`,
        }}
      />
      <div className={styles.header}>
        <div className={styles.line} />
        <span className={styles.number}>0{number}</span>
      </div>
      <div className={styles.body}>
        <img
          className={`${styles.icon} ${
            isAnimated && isAnimatedTwice ? styles.icon_active : ''
          }`}
          src={image}
          alt='Catalog Icon'
        />
        <span
          className={`${styles.name} ${
            isAnimated && isAnimatedTwice ? styles.name_active : ''
          }`}
          dangerouslySetInnerHTML={{ __html: title }}
        />
      </div>
    </Link>
  );
};

export { CatalogCard };
