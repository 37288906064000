import React, { useState } from 'react'

const Circle = React.forwardRef(({ cx, cy, ...rest }, ref) => {
  const [radius, setRadius] = useState(5)
  const onMouseEnter = () => setRadius(9)
  const onMousLeave = () => setRadius(5)

  return (
    <circle
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMousLeave}
      className="dist-mark"
      cx={cx}
      cy={cy}
      r={radius}
      fill="#F5F5F5"
      {...rest}
    />
  )
})

export { Circle }
