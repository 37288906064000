import styles from './AboutCompany.module.css'
import { BlockWithVerticalTitle } from '../BlockWithVerticalTitle'
import { useKeenSlider } from 'keen-slider/react'
import 'keen-slider/keen-slider.min.css'
import { Button } from '../Button'
import { useState, useRef, useEffect } from 'react'
import { Arrow } from '../Arrow'
import { useWindowWidth } from '../../hooks/useWindowWidth'
import GallerySlider from "../GallerySlider/GallerySlider";
import Link from "next/link";

const AboutCompany = ({data}) => {
  const [loaded, setLoaded] = useState(false)
  const [currentSlide, setCurrentSlide] = useState(0)
  const windowWidth = useWindowWidth()
  const [isAnimated, setIsAnimated] = useState()
  const animRef = useRef()
  const [openImg, setOpenImg] = useState(false)
  const [indexSlide, setIndexSlide] = useState(0)
  const [swiper, setSwiper] = useState()

  useEffect(() => {
    const options = { thresholds: [0.3] }
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0]
      if (entry.isIntersecting) {
        setIsAnimated(true)
      }
    }, options)
    observer.observe(animRef.current)
  }, [])
  const [refCallback, instanceRef] = useKeenSlider(
    {
      slides: { perView: 1 },
      initial: 0,
      loop: true,
      defaultAnimation: {
        duration: 1500,
      },
      slideChanged(slider) {
        setCurrentSlide(slider.track.details.rel)
      },
      created() {
        setLoaded(true)
      },
    },
    [
      (slider) => {
        let timeout
        let mouseOver = false
        function clearNextTimeout() {
          clearTimeout(timeout)
        }
        function nextTimeout() {
          clearTimeout(timeout)
          if (mouseOver) return
          timeout = setTimeout(() => {
            slider.next()
          }, 3000)
        }
        slider.on('created', () => {
          slider.container.addEventListener('mouseover', () => {
            mouseOver = true
            clearNextTimeout()
          })
          slider.container.addEventListener('mouseout', () => {
            mouseOver = false
            nextTimeout()
          })
          nextTimeout()
        })
        slider.on('dragStarted', clearNextTimeout)
        slider.on('animationEnded', nextTimeout)
        slider.on('updated', nextTimeout)
      },
    ],
  )

  return (
    <section
      className={`${styles.root} ${isAnimated ? styles.root_active : ''}`}
      ref={animRef}
    >
      <BlockWithVerticalTitle title="Компания" wrapperClassName={styles.block}>
        <div className={styles.content}>
          <div className={styles.about}>
            <h2 className={styles.title}>
              {data.index_company_title}
            </h2>
            <div className={styles.description} dangerouslySetInnerHTML={{__html: data.index_company_description}}>
            </div>
            <Link className="link" href={data.index_company_button_url}>
              <Button className={styles.button}>
                {data.index_company_button_text}
                <Arrow className={styles.arrow__inner} />
              </Button>
            </Link>
          </div>
          <div className={styles.slider}>
            {data.index_company_images && <div className={`${styles.slider} keen-slider`} ref={refCallback}>
              {JSON.parse(data.index_company_images).map((item, index) => (
                <img
                  key={index}
                  className={`${styles.img} keen-slider__slide`}
                  src={item}
                  onClick={(e) => {
                    setOpenImg(true)
                    setIndexSlide(index)
                  }}
                  alt={`slider_image_${index + 1}`}
                />
              ))}
            </div>}
            {windowWidth > 1280 && loaded && instanceRef.current && (
              <div className={styles.controls}>
                <div
                  className={styles.arrow}
                  onClick={instanceRef.current?.prev}
                >
                  <Arrow className={styles.arrow_left} />
                </div>
                <div
                  className={styles.arrow}
                  onClick={instanceRef.current?.next}
                >
                  <Arrow className={styles.arrow_right} />
                </div>
              </div>
            )}
            {windowWidth <= 1280 && loaded && instanceRef.current && (
              <div className={styles.dots}>
                {[
                  ...Array(
                    instanceRef.current.track.details.slides.length,
                  ).keys(),
                ].map((idx) => {
                  return (
                    <button
                      key={idx}
                      onClick={() => {
                        instanceRef.current?.moveToIdx(idx)
                      }}
                      className={`${styles.dot} ${
                        currentSlide === idx ? `${styles.dot_active}` : ''
                      }`}
                    ></button>
                  )
                })}
              </div>
            )}
          </div>
        </div>
      </BlockWithVerticalTitle>
      {windowWidth > 1278 && <GallerySlider
        images={JSON.parse(data.index_company_images)}
        activeImage={indexSlide}
        isOpen={openImg}
        onClose={() => setOpenImg(false)}/>}
    </section>
  )
}

export { AboutCompany }
