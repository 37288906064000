import styles from './Title.module.css'

const Title = ({ title, className = '' }) => {
  return (
    <div className={`${className} ${styles.title__wrapper}`}>
      <h2 className={styles.title}>{title}</h2>
    </div>
  )
}

export { Title }
