import { TooltipRepresentations } from '../../TooltipRepresentations'
import { TooltipRepresentationsMini } from '../../TooltipRepresentationsMini'
import { useState } from 'react'

const ReprSvg = ({ d, cx, cy, title, address, persons, tooltipText }) => {
  const [active, setActive] = useState(false)
  const [visible, setVisible] = useState(false)
  const [circleRadius, setCircleRadius] = useState(5)
  const [circleColor, setCircleColor] = useState('#F5F5F5')
  const onMouseEnter = () => setCircleRadius(9)
  const onMousLeave = () => setCircleRadius(5)

  return (
    <g className="group">
      <path
        className={active ? 'path active' : 'path'}
        d={d}
        fill="#262626"
        stroke="#454545"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <TooltipRepresentations
        trigger={
          <circle
            className="mark"
            cx={cx}
            cy={cy}
            r={circleRadius}
            fill={circleColor}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMousLeave}
          />
        }
        onOpen={() => {
          setActive(true)
          setVisible(false)
        }}
        isOpen={active}
        onClose={() => {
          setActive(false)
          setVisible(true)
        }}
        title={title}
        address={address}
        persons={persons}
        className={persons.length > 1 ? 'moscow' : ''}
      />
      <TooltipRepresentationsMini
        overlay={tooltipText}
        cx={cx}
        cy={cy}
        visible={visible}
        setVisible={setVisible}
        setCircleColor={setCircleColor}
        setCircleRadius={setCircleRadius}
        onClick={() => setActive(true)}
      />
    </g>
  )
}

export { ReprSvg }
