import React, {DetailedHTMLProps, HTMLAttributes, ReactNode, useEffect, useState} from 'react';
import styles from './NextButton.module.scss'
import cn from 'classnames'
import NextIcon from './next.svg'
import PrevIcon from "../PrevButton/prev.svg";

interface NextButtonProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>{
    allSlides?: number,
    active?: number | boolean
    icon?: ReactNode
}

const NextButton = ({className, onClick, active, allSlides=-1, icon}:NextButtonProps) => {
    const [inactive, setInactive] = useState(false)
    useEffect(() => {
        if(active==allSlides || typeof active == 'boolean' && active) {
            setInactive(true)
        } else {
            setInactive(false)
        }
    }, [active])
    return (
        <div onClick={onClick} className={cn(styles.button, className, {
            [styles.inactive]: inactive
        })}>
            {icon ? icon :  <NextIcon viewBox={"0 0 48 48"}/>}
        </div>
    );
};

export default NextButton;