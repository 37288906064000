import React, {useState} from 'react';
import styles from './NewsItemPreview.module.scss'
import NewsPopup from "../Popups/NewsPopup/NewsPopup";

interface NewsPageItemPreviewProps {
  item: {
    id: number
    image: string,
    content: string,
    title: string
  }
}

const NewsItemPreview = ({item}: NewsPageItemPreviewProps) => {

  const [openModal, setOpenModal] = useState(false)

  return (
    <>
      <div onClick={() => setOpenModal(true)} className={styles.root}>
        <img src={item.image} alt="" className={styles.img}/>
        <div className={styles.bottom}>
          <div>
            <h3 className={styles.title}>{item.title}</h3>
            <div className={styles.text} dangerouslySetInnerHTML={{__html: item.content.slice(0, 120) + (item.content.length>120 ? '...' : '')}}></div>
          </div>
          <button onClick={() => setOpenModal(true)} className={styles.btn}>
            <span>Подробнее</span>
            <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.5 2L13.5 7M13.5 7L8.5 12M13.5 7H1" stroke="#13AD37" stroke-width="2" stroke-linecap="square"/>
            </svg>
          </button>
        </div>

      </div>
      <NewsPopup item={item} isOpen={openModal} onRequestClose={() => setOpenModal(false)}/>
    </>
  );
};

export default NewsItemPreview;
