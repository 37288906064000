import React, {useState} from 'react';
import Modal from "../../Modal";
import styles from './NewsPopup.module.scss'

interface NewsPopupProps {
  isOpen: boolean
  onRequestClose: () => void
  item: {
    id: number
    image: string,
    content: string,
    title: string
  }
}

const NewsPopup = ({isOpen, item, onRequestClose}: NewsPopupProps) =>  {

  return (
    <Modal className={styles.root} isOpen={isOpen} onRequestClose={onRequestClose}>
      <div className={styles.inner}>
        <img src={item.image} alt="" className={styles.img}/>
          <h3 className={styles.title}>{item.title}</h3>
          <div className={styles.text} dangerouslySetInnerHTML={{__html: item.content}}></div>
      </div>
      <svg className={styles.xMark} onClick={onRequestClose} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M24 2L2 24M2 2L24 24" stroke="#8E9AA9" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
      </svg>
    </Modal>
  );
};

export default NewsPopup;