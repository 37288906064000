import { useEffect, useRef, useState } from 'react'

const TooltipRepresentationsMini = ({
  cx,
  cy,
  overlay,
  visible,
  setVisible,
  onClick,
  setCircleRadius,
  setCircleColor,
}) => {
  const textRef = useRef(null)
  const [textWidth, setTextWidth] = useState(0)
  const [textHeight, setTextHeight] = useState(0)

  const rectRef = useRef(null)
  const [rectWidth, setRectWidth] = useState(0)
  const [rectHeight, setRectHeight] = useState(0)

  const arrowRef = useRef(null)
  const [arrowWidth, setArrowWidth] = useState(0)
  const [arrowHeight, setArrowHeight] = useState(0)

  useEffect(() => {
    setTimeout(() => {
      setTextWidth(textRef.current.getBBox().width)
      setTextHeight(textRef.current.getBBox().height)
      setRectWidth(rectRef.current.getBBox().width)
      setRectHeight(rectRef.current.getBBox().height)
      setArrowWidth(arrowRef.current.getBBox().width)
      setArrowHeight(arrowRef.current.getBBox().height)
    }, 100)
  }, [visible])

  useEffect(() => {
    setTimeout(() => setVisible(true), 200)
  }, [setVisible])

  return (
    <svg
      className="repr__tooltip"
      style={{
        userSelect: 'none',
        cursor: 'pointer',
        transition: 'opacity 0.3s ease-in-out',
        opacity: visible ? 1 : 0,
      }}
      onClick={onClick}
      onMouseEnter={() => {
        setCircleRadius(9)
        setCircleColor('#D6D6D6')
      }}
      onMouseLeave={() => {
        setCircleRadius(5)
        setCircleColor('#f5f5f5')
      }}
    >
      <rect
        x={cx - rectWidth / 2}
        ref={rectRef}
        y={cy - rectHeight / 2 - 20}
        width={textWidth + 20}
        height={textHeight + 12}
        rx="14"
        fill="#13AD37"
      />
      <text
        className="tooltip-text"
        ref={textRef}
        x={cx - textWidth / 2}
        y={cy - textHeight / 2 - 9}
        fill="red"
      >
        {overlay}
      </text>
      <svg
        ref={arrowRef}
        x={cx - arrowWidth + 6.5}
        y={cy - arrowHeight - 2}
        width="13"
        height="5"
        viewBox="0 0 13 5"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.50005 5L6.4573e-07 -2.79679e-07L13 8.56817e-07L6.50005 5Z"
          fill="#13AD37"
        />
      </svg>
    </svg>
  )
}

export { TooltipRepresentationsMini }
