import { useRef } from "react";
import Layout from "../Layout";
import { Banner } from "../components/Banner";
import { Catalog } from "../components/Catalog";
import { AboutCompany } from "../components/AboutCompany";
import { Partners } from "../components/Partners";
import { Map } from "../components/Map";
import { Companies } from "../components/Companies";
import { FAQ } from "../components/FAQ";
import { Feedback } from "../components/Feedback";
import { GetStaticProps } from "next";
import { axiosServer } from "../axios/axiosCustom";
import { ICategories, ICategoryItem } from "../interfaces/categories.interface";
import { API, API_SSR } from "../api/api";
import News from "../components/News/News";
import { INews } from "../interfaces/news.interface";
import { IContacts, IContactsPayload } from "../interfaces/contacts.interface";
import { IHomePage, IHomePayload } from "../interfaces/main.interface";
import Head from "next/head";
import { returnTrue } from "../helpers/returnTrue";

const Index = ({
  categories,
  contacts,
  homeData,
  news,
  regions,
}: PageProps) => {
  const tooltipRef = useRef(null);
  return (
    <>
      <Head>
        <title>{homeData.meta.meta_tags.title}</title>
        <meta
          name="description"
          content={homeData.meta.meta_tags.description}
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={homeData.meta.meta_tags.title} />
        <meta
          property="og:description"
          content={homeData.meta.meta_tags.description}
        />
      </Head>
      <Layout globalSettings={homeData.payload.global_settings}>
        <Banner />
        <Catalog
          categories={categories.sort((a, b) =>
            a.id == 3 ? -1 : b.id == 3 ? 1 : 0
          )}
          isGrid={false}
          className={""}
        />
        <AboutCompany data={homeData.payload.page_settings} />
        <Partners />
        <Map contacts={contacts} regions={regions} tooltipRef={tooltipRef} />
        <Companies />
        <FAQ data={homeData.payload.question_answers} />
        <News news={news} />
        <Feedback />
        <div ref={tooltipRef} id="tooltip-map" />
      </Layout>
    </>
  );
};

interface PageProps {
  categories: ICategoryItem[];
  contacts: IContactsPayload;
}

export const getStaticProps: GetStaticProps<PageProps> = async () => {
  try {
    const { data } = await axiosServer.get<ICategories>(
      API_SSR.getCategoriesPage
    );
    const { data: news } = await axiosServer.get<INews>(API_SSR.getNews);
    const { data: homeData } = await axiosServer.get<IHomePage>(
      API_SSR.getMainPage
    );
    const { data: russia } = await axiosServer.get<any>(
      API_SSR.getRegions("Россия")
    );
    const { data: kazahchstan } = await axiosServer.get<any>(
      API_SSR.getRegions("Казахстан")
    );
    const { data: contacts } = await axiosServer.get<IContacts>(
      API_SSR.getContactsPage
    );
    if (!news || !russia || !kazahchstan || !data || !kazahchstan) {
      return {
        notFound: true,
        revalidate: 60,
      };
    }

    return {
      props: {
        contacts: contacts.payload,
        categories: data.data,
        news: news,
        homeData: homeData,
        regions: russia.data.concat(kazahchstan.data),
      },
      revalidate: 60,
    };
  } catch (e) {
    return {
      notFound: true,
      revalidate: 60,
    };
  }
};

interface PageProps {
  categories: ICategoryItem[];
  news: INews;
  homeData: IHomePage;
  regions: any[];
}

export default Index;
