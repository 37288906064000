import styles from './Feedback.module.css'
import { BlockWithVerticalTitle } from '../BlockWithVerticalTitle'
import { InputUnderline } from '../InputUnderline'
import { Button } from '../Button'
import { useForm } from 'react-hook-form'
import { Checkbox } from '../Checkbox'
import { SuccessModal } from '../SuccessModal'
import { useState, useRef, useEffect } from 'react'
import {axiosForm} from "../../axios/axiosCustom";
import {API} from "../../api/api";

const Feedback = () => {
  const [isAnimated, setIsAnimated] = useState()
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const animRef = useRef(null)

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0]
      if (entry.isIntersecting) {
        setIsAnimated(true)
      }
    })
    observer.observe(animRef.current)
  }, [])

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  })

  const onSubmit = async (data) => {
    const dataToSend = {
      "fields":
          {
            "TITLE":'Форма на главной странице "У Вас остались вопросы?"',
            "NAME": data.name,
            "EMAIL": [{
              "VALUE" : data.email,
              "VALUE_TYPE": "WORK"
            }],
            "PHONE":[{
              "VALUE" : data.phone,
              "VALUE_TYPE": "WORK"
            }],
          }
    }
    const fetcher = await axiosForm({
        url: API.getForm,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: JSON.stringify(dataToSend),
      },
    )

    if (fetcher.status === 200) setIsFormSubmitted(true)
  }

  return (
    <section className={styles.root} ref={animRef}>
      <BlockWithVerticalTitle
        title={'Обратная связь'}
        wrapperClassName={styles.block}
        titleClassName={styles.title_small}
      >
        <div className={styles.content}>
          <h3
            className={`${styles.title} ${
              isAnimated ? styles.title_active : ''
            }`}
          >
            У Вас остались вопросы?
          </h3>
          <p
            className={`${styles.description} ${
              isAnimated ? styles.description_active : ''
            }`}
          >
            Оставьте ваши контактные данные и наши специалисты оперативно
            проконсультируют вас по интересующему вопросу
          </p>
          <form
            className={styles.form}
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <div
              className={`${styles.form_upper} ${
                errors && errors['email'] ? styles.form_error : ''
              }`}
            >
              <InputUnderline
                type="text"
                name="Имя"
                fieldName="name"
                required
                register={register}
                errors={errors}
                errorText="Введите корректное имя"
                pattern={/^.{1,120}$/}
                className={`${isAnimated ? styles.label_active_1 : ''}`}
              />
              <InputUnderline
                type="text"
                name="Телефон"
                fieldName="phone"
                required
                register={register}
                errors={errors}
                errorText="Введите корректный телефон"
                pattern={
                  /(^8|7|\+7)((\d{10})|(\s\(\d{3}\)\s\d{3}\s\d{2}\s\d{2}))/
                }
                className={`${isAnimated ? styles.label_active_2 : ''}`}
              />
              <InputUnderline
                type="text"
                name="E-mail"
                fieldName="email"
                required
                register={register}
                errors={errors}
                errorText="Введите корректный email"
                pattern={/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i}
                className={`${isAnimated ? styles.label_active_3 : ''}`}
              />
              <Button
                className={`${styles.submit} ${
                  isAnimated ? styles.submit_active : ''
                }`}
                type="submit"
              >
                Отправить
              </Button>
            </div>
            <div className={`${styles.form_lower}`}>
              <Checkbox
                name="agreement"
                required
                register={register}
                errors={errors}
                className={`${isAnimated ? styles.checkbox_active : ''}`}
              >
                Я согласен на обработку моих персональных данных
              </Checkbox>
            </div>
          </form>
          <div
            className={`${styles.bg} ${isAnimated ? styles.bg_active : ''}`}
          />
        </div>
      </BlockWithVerticalTitle>
      {Object.keys(errors).length === 0 && isFormSubmitted && (
        <SuccessModal
          isFormSubmitted={isFormSubmitted}
          setIsFormSubmitted={setIsFormSubmitted}
        />
      )}
    </section>
  )
}

export { Feedback }
