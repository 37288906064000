import styles from './BlockWithVerticalTitle.module.css'
import { Wrapper } from '../Wrapper'
import { Title } from '../Title'

const BlockWithVerticalTitle = ({
  children,
  title,
  className = '',
  wrapperClassName = '',
  titleClassName = '',
}) => {
  return (
    <div className={`${styles.root} ${className}`}>
      <Wrapper className={`${styles.wrapper} ${wrapperClassName}`}>
        <Title title={title} className={titleClassName} />
        {children}
      </Wrapper>
    </div>
  )
}

export { BlockWithVerticalTitle }
