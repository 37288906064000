import cn from "classnames";
import {
    DetailedHTMLProps,
    forwardRef,
    HTMLAttributes,
    MouseEvent, ReactNode,
    useCallback,
    useEffect,
    useRef,
    useState
} from "react";
import { createPortal } from "react-dom";
import { disableBodyScroll, clearAllBodyScrollLocks  } from "body-scroll-lock";

import styles from "./Modal.module.scss";

interface ModalProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>{
    isOpen:  boolean,
    onRequestClose: () => void,
    children: ReactNode,
    overlayClassName?: string
}

const Modal = forwardRef<HTMLDivElement, ModalProps>(
    ({ isOpen, onRequestClose, children, className, overlayClassName }, ref) => {
        const overlayRef = useRef<HTMLDivElement>(null);
        const [mounted, setMounted] = useState(false);
        const onOverlayClick = useCallback(
            (event: MouseEvent<HTMLDivElement>) => {
                if (!(event.target as HTMLElement).closest(`.${styles.root}`)) {
                    onRequestClose?.();
                }
            },
            [onRequestClose]
        );

        useEffect(() => {
            setMounted(true);
        }, []);

        useEffect(() => {
            if (isOpen) {
                disableBodyScroll(overlayRef.current, {reserveScrollBarGap: true});
            } else if (!isOpen) {
              clearAllBodyScrollLocks();
            }
        }, [isOpen]);

        return isOpen && mounted
            ? createPortal(
                <div
                    className={cn(styles.overlay, overlayClassName)}
                    onClick={onOverlayClick}
                    ref={overlayRef}
                >
                    <div ref={ref} className={cn(styles.root, className)}>
                        {children}
                    </div>
                </div>,
                document.body
            )
            : null;
    }
);

export default Modal;
