import { useWindowWidth } from '../../hooks/useWindowWidth'
import { Title } from '../Title'
import { Wrapper } from '../Wrapper'
import styles from './Partners.module.css'
import { useState, useRef, useEffect } from 'react';

const PARTNERS_INFO = [
  {
    title: 'Заказчики',
    icon: '/images/partners/partner-1.svg',
    text:
      'Обеспечиваем производство продукции на территории РФ и имеем собственный склад с большим ассортиментом продукции в наличии.',
  },
  {
    title: 'Дистрибьюторы',
    icon: '/images/partners/partner-2.svg',
    text:
      'Обладаем собственным распределительным центром и большим производственным потенциалом, что делает нас надежным партнером на рынке.',
  },
  {
    title: 'Монтажники',
    icon: '/images/partners/partner-3.svg',
    text:
      'Проводим сертифицированное обучение для монтажных бригад и компаний, предоставляем возможность повысить качество выполняемых работ.',
  },
  {
    title: 'Проектировщики',
    icon: '/images/partners/partner-4.svg',
    text:
      'Предоставляем постоянно обновляемые профессиональные инструменты для проектирования и расчета продукции.',
  },
]

const PartnersContent = () => {
  const [isAnimated, setIsAnimated] = useState();
  const animRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      if (entry.isIntersecting) {
        setIsAnimated(true);
      }
    });
    observer.observe(animRef.current);
  }, []);

  return (
    <div className={styles.items} ref={animRef}>
      {PARTNERS_INFO.map(({ title, text, icon }, index) => {
        return (
          <div
            className={`${styles.card} ${
              isAnimated ? styles[`card_active_${index + 1}`] : ''
            }`}
            key={index}
          >
            <div className={styles.title_big}>{title}</div>
            <div className={styles.icon__wrapper}>
              <img src={icon} className={styles.icon} alt='Icon' />
            </div>
            <h3 className={styles.title}>{title}</h3>
            <p className={styles.text}>{text}</p>
          </div>
        )
      })}
    </div>
  )
}

const Partners = () => {
  const windowWidth = useWindowWidth()

  return (
    <section className={styles.root}>
      <Wrapper className={styles.wrapper}>
        <Title title="Партнеры" />
        {windowWidth && windowWidth >= 671 && <PartnersContent />}
      </Wrapper>
      {windowWidth && windowWidth <= 670 && <PartnersContent />}
    </section>
  )
}

export { Partners }
