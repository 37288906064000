import { useRef, useState, useEffect } from 'react'
import styles from './FAQ.module.css'
import { BlockWithVerticalTitle } from '../BlockWithVerticalTitle'
import { ModalCallback } from '../ModalCallback'
import Link from "next/link";

const FAQItem = ({ question,  children, className }) => {
  const [isOpen, setIsOpen] = useState()

  return (
    <div
      className={`${styles.item} ${
        isOpen ? styles.item_open : ''
      } ${className}`}

    >
      <div onClick={() => setIsOpen(!isOpen)} className={styles.question__wrapper}>
        <div className={styles.question}>{question}</div>
        <div className={styles.button}>
          <img
            src={isOpen ? '/images/minus.svg' : '/images/plus.svg'}
            alt={isOpen ? '-' : '+'}
          />
        </div>
      </div>
      <div className={`${styles.answer} ${isOpen ? styles.answer_open : ''}`}>
        {children}
      </div>
    </div>
  )
}

const FAQ = ({data}) => {
  const [isAnimated, setIsAnimated] = useState()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const animRef = useRef()

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0]
      if (entry.isIntersecting) {
        setIsAnimated(true)
      }
    })
    observer.observe(animRef.current)
  }, [])

  const replaceLinkToComponent = (str, isButton, link, text) => {
    const answerParts = str.split('{{link}}');
    return (
    <span className={styles.answer__inner}>
      {answerParts.map((part, index) => (
        <span key={index}>
          {part}
          {index < answerParts.length - 1 && (isButton ? <span onClick={() => setIsModalOpen(true)} className={styles.answer__link}>{text}</span> : <Link href={link} className={styles.answer__link}>{text}</Link>)}
        </span>
      ))}
    </span>)
  }

  return (
    <section
      className={`${styles.root} ${isAnimated ? styles.root_active : ''}`}
      ref={animRef}
    >
      <BlockWithVerticalTitle
        title={'Ответы на вопросы'}
        wrapperClassName={styles.block}
        className={styles.title_rotated}
      >
        <div className={styles.content}>
          {data.map(faqItem =>
            <FAQItem
              className={isAnimated ? styles.item_active : ''}
              question={faqItem.question}
            >

                {replaceLinkToComponent(faqItem.answer, faqItem.answer_link_is_feedback, faqItem.answer_link_url, faqItem.answer_link_text)}
            </FAQItem>
          )}
        </div>
        {isModalOpen && (
          <ModalCallback
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
          />
        )}
      </BlockWithVerticalTitle>
    </section>
  )
}

export { FAQ }
