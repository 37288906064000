import styles from './Banner.module.css'
import { Wrapper } from '../Wrapper'
import { Button } from '../Button'
import { ModalCallback } from '../ModalCallback'
import { useEffect, useRef, useState } from 'react'

const columns = [
  { title: 'Москва', description: '<span>Центральный склад</span><span>Головной офис</span>' },
  { title: 'Казахстан', description: '<span>Астана филиал</span><span>Алматы склад</span>' },
  { title: '20 000+', description: '<span>Реализованных объектов</span><span>на продукции SPL</span>' },
  { title: '8', description: '<span>Представительств</span> по РФ и СНГ' },
  { title: '5', description: '<span>Производственных площадок</span>' },
];

const Banner = () => {
  const [isAnimated, setIsAnimated] = useState()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const animRef = useRef(null)

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0]
      if (entry.isIntersecting) {
        setIsAnimated(true)
      }
    })
    observer.observe(animRef.current)
  }, [])

  return (
    <section
      className={`${styles.root} ${isAnimated ? styles.root_active : ''}`}
      ref={animRef}
    >
      <div className={`${styles.bg} ${isAnimated ? styles.bg_active : ''}`} />
      <Wrapper className={styles.wrapper}>
        <h1
          className={`${styles.title} ${isAnimated ? styles.title_active : ''}`}
        >
          Инженерная инфраструктура от ведущего Российского производителя
        </h1>
        <p
          className={`${styles.description} ${
            isAnimated ? styles.description_active : ''
          }`}
        >
          SPL — Один из ведущих производителей электротехнических и слаботочных
          решений на Российском рынке
        </p>
        <div className={styles.container}>
          <div
            className={`${styles.line} ${isAnimated ? styles.line_active : ''}`}
          />
          <Button
            className={`${styles.submit} ${
              isAnimated ? styles.submit_active : ''
            }`}
            onClick={() => setIsModalOpen(true)}
          >
            Оставить заявку
          </Button>
          {isModalOpen && (
            <ModalCallback
              isOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
            />
          )}
        </div>
        <div className={styles.columns}>
          {columns.map((column, index) => (
            <div
              className={`${styles.column} ${
                isAnimated ? styles.column_active : ''
              }`}
              key={index}
            >
              <span className={styles.column__title}>{column.title}</span>
              <span className={styles.column__description}>
                <div className={styles.column__list} dangerouslySetInnerHTML={{ __html: column.description }} />
              </span>
            </div>
          ))}
        </div>
      </Wrapper>
    </section>
  )
}
export { Banner }
