import React, {DetailedHTMLProps, HTMLAttributes, ReactNode, useEffect, useState} from 'react';
import styles from './PrevButton.module.scss'
import cn from 'classnames'
import PrevIcon from './prev.svg'

interface NextButtonProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>{
    active?: number
    icon?: ReactNode
}

const PrevButton = ({className, onClick, active, icon}:NextButtonProps) => {
    const [inactive, setInactive] = useState(false)
    useEffect(() => {
        if(active==0) {
            setInactive(true)
        } else {
            setInactive(false)
        }
    }, [active])
    return (
        <div onClick={onClick} className={cn(styles.button, className, {
            [styles.active]: !inactive
        })}>
            {icon ? icon : <PrevIcon viewBox={"0 0 48 48"}/>}
        </div>
    );
};

export default PrevButton;