import { Title } from '../Title'
import { Wrapper } from '../Wrapper'
import { CatalogCard } from './components/CatalogCard'
import styles from './Catalog.module.css'
import { useWindowWidth } from '../../hooks/useWindowWidth'
import cn from 'classnames'

const CatalogContent = ({ className = '', categories }) => {

  return (
    <div className={`${styles.root} ${className}`}>
      {categories && categories.map(({ title, image, slug }, index) => {
        const row = Math.floor(index / 3);
        const pos = index % 3;
        const isLarge = (row % 2 === 0 && pos === 0) || (row % 2 !== 0 && pos === 2);

        return (
          <CatalogCard
            className={cn(styles.card, isLarge && styles.large)}
            link={`/products/${slug}`}
            title={title}
            image={image}
            number={index + 1}
            key={index}
            index={index}
          />
        );
      })}
    </div>
  )
}

const Catalog = ({className, categories, isGrid}) => {
  const windowWidth = useWindowWidth()

  return (
    <section>
      {isGrid ? <CatalogContent categories={categories} className={cn(styles.grid)}/> :
        <>
          <Wrapper className={cn(styles.wrapper, className)}>
            <Title title="Каталог"/>
            {windowWidth && windowWidth > 600 && (
              <CatalogContent categories={categories} className={cn(styles.grid, styles.main)}/>
            )}
          </Wrapper>
          {windowWidth && windowWidth <= 600 && (
            <CatalogContent categories={categories} className={cn(styles.grid, styles.main)}/>
          )}
        </>
      }
    </section>
  )
}

export { Catalog }
