const Arrow = ({ className }) => {
  return (
    <svg
      width='15'
      height='14'
      viewBox='0 0 15 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M7.71967 2.53033L7.18934 2L8.25 0.93934L8.78033 1.46967L7.71967 2.53033ZM13.25 7L13.7803 6.46967L14.3107 7L13.7803 7.53033L13.25 7ZM8.78033 12.5303L8.25 13.0607L7.18934 12L7.71967 11.4697L8.78033 12.5303ZM0.75 7.75H0V6.25H0.75V7.75ZM8.78033 1.46967L13.7803 6.46967L12.7197 7.53033L7.71967 2.53033L8.78033 1.46967ZM13.7803 7.53033L8.78033 12.5303L7.71967 11.4697L12.7197 6.46967L13.7803 7.53033ZM13.25 7.75H0.75V6.25H13.25V7.75Z'
        fill='white'
      />
    </svg>
  );
};

export { Arrow };
