import styles from './Tooltip.module.css'
import Popup from 'reactjs-popup'
import { useWindowWidth } from '../../hooks/useWindowWidth'
import CloseIcon from "../CloseIcon/CloseIcon";
import Link from "next/link";

const TooltipRepresentations = ({
  className = '',
  isOpen = true,
  trigger,
  title,
  address,
  persons,
  onOpen,
  onClose,
}) => {
  const windowWidth = useWindowWidth()

  return (
    <Popup
      trigger={trigger}
      open={isOpen}
      position={['top center', 'bottom center', 'right center', 'left center']}
      closeOnDocumentClick
      lockScroll={windowWidth && windowWidth <= 1050}
      onOpen={onOpen}
      onClose={onClose}
      modal={windowWidth && windowWidth <= 1050}
      className="tooltip"
    >
      {(close) => (
        <div className={`${styles.root} ${className}`}>
          <button className={styles.button} onClick={close}>
            <CloseIcon />
          </button>
          <h2 className={styles.title}>{title}</h2>
          {address && <p className={styles.address}>{address}</p>}

          <div
            className={
              persons.length !== 1
                ? `${styles.persons} ${styles.grid}`
                : styles.persons
            }
          >
            {persons.map(({ position, name, phone, mail }, index) => {
              return (
                <div key={index}>
                  <h3 className={styles.position}>{position}</h3>
                  <ul className={styles.list}>
                    <li className={styles.item}>{name}</li>
                    <li className={styles.item}>
                      <Link href={`tel:${phone}`}>{phone}</Link>
                    </li>
                    <li className={styles.item}>
                      <Link href={`mailto:${mail}`}>{mail}</Link>
                    </li>
                  </ul>
                </div>
              )
            })}
          </div>
        </div>
      )}
    </Popup>
  )
}

export { TooltipRepresentations }
