import styles from './Map.module.css'
import { useState, useEffect } from 'react'
import { Distributor, Representations } from './components'
import { Button } from '../Button'
import { Title } from '../Title'
import { Wrapper } from '../Wrapper'

const Map = ({ tooltipRef, contacts, regions }) => {
    const [activeMap, setActiveMap] = useState('representations')

    useEffect(() => {
        const tooltips = document.querySelectorAll('#tooltip-map')
        tooltips.forEach((tooltip) => {
            tooltip.style.display = activeMap === 'distributor' ? 'block' : 'none'
        })
    }, [activeMap])

    return (
        <section className={styles.root}>
            <Wrapper className={styles.wrapper}>
                <Title className={styles.title} title="SPL на карте" />
            </Wrapper>
            <img
                className={`${styles.shape} ${
                    activeMap === 'distributor' ? styles.shape_dist : ''
                }`}
                src="/images/map-bg.svg"
                alt="Shape"
            />
            <div className={styles.toggle}>
                <Button
                    className={`${styles.button} ${
                        activeMap === 'distributor' ? styles.button_active : ''
                    }`}
                    onClick={() => setActiveMap('distributor')}
                >
                    Дистрибьюторы
                </Button>
                <Button
                    className={`${styles.button} ${
                        activeMap === 'representations' ? styles.button_active : ''
                    }`}
                    onClick={() => setActiveMap('representations')}
                >
                    Представительства
                </Button>
            </div>
            <div className={styles.content}>
                <Representations
                    contacts={contacts}
                    className={`${styles.map__rep} ${
                        activeMap === 'representations' ? styles.map__rep_active : ''
                    }`}
                />
                <Distributor
                    regions={regions}
                    tooltipRef={tooltipRef}
                    className={`${styles.map__dist} ${
                        activeMap === 'distributor' ? styles.map__dist_active : ''
                    }`}
                />
            </div>
        </section>
    )
}

export { Map }
