import React, {useState} from 'react';
import styles from './News.module.scss'
import {BlockWithVerticalTitle} from '../BlockWithVerticalTitle/index'
import {Swiper, SwiperSlide} from "swiper/react";
import ProductOthersPreview from "../ProductOthersPreview/ProductOthersPreview";
import NextButton from "../NextButton/NextButton";
import PrevButton from "../PrevButton/PrevButton";
import SwiperClass from "swiper/types/swiper-class";
import {useWindowWidth} from "../../hooks/useWindowWidth";
import NewsItemPreview from "../NewsItemPreview/NewsItemPreview";
import Container from "../Container/Container";
import {INews} from "../../interfaces/news.interface";


const newsItems = [
  {
    title: 'Проводим сертифицированное обучение для монтажных бригад ',
    text: 'Обеспечиваем производство продукции на территории РФ и имеем собственный склад с большим ассортиментом продукции в наличии.Занимаем твердую лидирующую позицию в сегменте производства кабеленесущих систем, а именно кабельных каналов и аксессуаров к ним, гофрированных и гладких жестких труб, различных электроустановочных и коммутационных изделий.\n' +
      'Занимаем твердую лидирующую позицию в сегменте производства кабеленесущих систем, а именно кабельных каналов и аксессуаров к ним, гофрированных и гладких жестких труб, различных электроустановочных и коммутационных изделий.',
    image: '/images/news-1.png',
  },
  {
    title: 'Знаем все о производстве',
    text: 'Обеспечиваем производство продукции на территории РФ и имеем собственный склад с большим ассортиментом продукции в наличии. Обеспечиваем производство продукции на территории РФ и имеем собственный склад с большим ассортиментом продукции в наличии. Обеспечиваем производство продукции на территории РФ и имеем собственный склад с большим ассортиментом продукции в наличии.',
    image: '/images/news-2.png',
  },
  {
    title: 'Проводим сертифицированное обучение для монтажных бригад ',
    text: 'Обеспечиваем производство продукции на территории РФ и имеем собственный склад с большим ассортиментом продукции в наличии. Обеспечиваем производство продукции на территории РФ и имеем собственный склад с большим ассортиментом продукции в наличии. Обеспечиваем производство продукции на территории РФ и имеем собственный склад с большим ассортиментом продукции в наличии. Обеспечиваем производство продукции на территории РФ и имеем собственный склад с большим ассортиментом продукции в наличии. ',
    image: '/images/news-3.png',
  },
  {
    title: 'Проводим сертифицированное обучение для монтажных бригад ',
    text: 'Обеспечиваем производство продукции на территории РФ и имеем собственный склад с большим ассортиментом продукции в наличии.Занимаем твердую лидирующую позицию в сегменте производства кабеленесущих систем, а именно кабельных каналов и аксессуаров к ним, гофрированных и гладких жестких труб, различных электроустановочных и коммутационных изделий.\n' +
      'Занимаем твердую лидирующую позицию в сегменте производства кабеленесущих систем, а именно кабельных каналов и аксессуаров к ним, гофрированных и гладких жестких труб, различных электроустановочных и коммутационных изделий.',
    image: '/images/news-1.png',
  },
]
interface NewsProps {
  news: INews
}
const News = ({news}: NewsProps) => {
  const [swiper, setSwiper] = useState<SwiperClass>()
  const [activeSlide, setActiveSlide] = useState(0)
  const [activeFirstSlide, setActiveFirstSlide] = useState(0)


  return (
    <BlockWithVerticalTitle className={styles.wrapper} title={'Новости'}>
      <div className={styles.root}>
        <Swiper
          slidesPerView={4}
          onSwiper={setSwiper}
          className={styles.slider}
          spaceBetween={24}
          onSlideChange={e => {
            setActiveFirstSlide(e.activeIndex)
            setActiveSlide(e.activeIndex + 3 - 1)
          }}
          breakpoints={{
            1365: {
              slidesPerView: 3
            },
            991: {
              slidesPerView: 2
            },
            100: {
              watchOverflow: true,
              freeMode: true,
              slidesPerView: 'auto'
            }
          }}
        >
          {news.data.map((newItem, i) =>
            <SwiperSlide key={newItem.id} className={styles.slide}>
              <NewsItemPreview item={newItem}/>
            </SwiperSlide>
          )}
        </Swiper>
        {news.data.length > 3 ?
          <>
            <NextButton className={styles.next} active={activeSlide} allSlides={newsItems.length - 1} onClick={() => swiper.slideNext()}/>
            <PrevButton className={styles.prev} active={activeFirstSlide} onClick={() => swiper.slidePrev()}/>
          </>: null}
      </div>
    </BlockWithVerticalTitle>
  );
};

export default News;